<template>
    <div class="outside">
        <div class="potential">
            <rxNavBar title="通知" androidOrIOSFlag="true"></rxNavBar>
        </div>
        <!-- 顶部固定区域-->
        <div class="header">
            <!-- 合同切换区域-->
            <div class="choose">
                <div class="choose-one">
                    <div :class="type=='1'?'choose-one-textActive':'choose-one-text'" @click="showInformation" style="display: flex;align-items: center;justify-content: center"><div style="font-size: 15px">消息中心</div>  <div :class="type==1?'inform-amount':'amount'">{{informAmount.noticetCount>99?99+'+':informAmount.noticetCount}}</div></div>
                    <div class="choose-one-icon" v-show="type=='1'"></div>
                </div>
                <div class="choose-line"></div>
                <div class="choose-one">
                    <div :class="type!='1'?'choose-one-textActive':'choose-one-text'" @click="showBacklog"  style="display: flex;align-items: center;justify-content: center"><div style="font-size: 15px">待办中心</div>  <div :class="type==2?'inform-amount':'amount'">{{informAmount.bedealtCount>99?99+'+':informAmount.bedealtCount}}</div></div>
                    <div class="choose-one-icon" v-show="type!='1'"></div>
                </div>
            </div>
            <!-- 搜索区域和类型区域-->
            <div class="searchAndType">
                <!-- 类型区域-->
                <div class="types">
                    <span class="type" v-if="typeList[number]" @click="isShow = !isShow" id="downList">{{typeList[number].dictionaryTitle}}</span>
                    <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
                    <span :class="isChooseLeaseChange=='1'?'leaseChangeActive':'leaseChange'" >{{typeName}}</span>
                </div>
            </div>
        </div>
        <!--合同列表区域-->
        <div class="blankWeight"></div>
        <div class="contracts">
            <van-pull-refresh v-model="refreshing"  @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="noContent ? '' : '已经到底了'"
                        @load="this.onLoad"
                >
                    <!--合同列表-->
                    <div class="contract" v-for="(item,index) in informList" :key="index">
                        <div :class="item.noticeStatus=='未读'||item.dealtStatus=='待办'?'top-title-green':'top-title-red'">{{item.noticeStatus?item.noticeStatus:item.dealtStatus}}</div>
                        <div class="position">{{item.title}}</div>
                        <div class="contract-info" @click="detailsClick(index)">
                            <span class="contract-text">添加时间：{{item.addTime.substring(0,10)}}</span>
                            <!--<span class="contract-text">创建人：{{item.addstaffName}}</span>-->
                            <div class="contract-text">内容：{{item.introduction}}</div>
                        </div>


                    </div>

                </van-list>
            </van-pull-refresh>
        </div>
        <van-popup v-model="isShow" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="typeList"
                    @cancel="isShow = false"
                    :default-index="number"
                    value-key="dictionaryTitle"
                    @confirm="confirmType"
            />
        </van-popup>
    </div>
</template>

<script>
    import {NavBar, Picker, Popup,PullRefresh,Overlay, } from 'vant';
    import Vue from 'vue';
    import { List ,Toast,Dialog} from 'vant';
    Vue.use(List);
    import {
       queryBedealtCount,queryBedealtList
    } from "../../../getData/getData";
    import {responseUtil, checkAuthList, getStaffId, globaluserId} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
    export default {
        name: "contractNew",
        components:{
            [NavBar.name]:NavBar,
            [Toast.name]:Toast,
            [Dialog.name]:Dialog,
            [Picker.name]: Picker,
            [Popup.name]:Popup ,
            rxNavBar,
            dialMobile,
            [PullRefresh.name]:PullRefresh,
            [Overlay .name]: Overlay ,
        },
        data(){
            return{
                type:'1',       //1为消息中心,2为待办中心
                typeName:'消息中心',
                typeList:[{dictionaryTitle:'未读'},{dictionaryTitle:'已读'},{dictionaryTitle:'全部'}],
                number:0,
                isShow:false,
                isChooseLeaseChange:'0',
                refreshing:false,
                finished:false,
                noContent:false,
                loading:false,
                informList:[],
                informAmount:{noticetCount:0,bedealtCount:0},  //消息通知，待办通知数量
                page:{currentPage:1,numberPage:3,}
            }
        },
        created() {
            this.getInformAmount()  //获取通知数量

        },
        mounted() {
        },
        /*beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name == 'informDetails'){
                to.meta.keepAlive = true;
                next(vm=>{
                    //vm.getInformAmount()
                    vm.showInformation()
                })
            }else {
                to.meta.keepAlive = false;
                next()
            }

        },*/
        methods:{
            //获取通知数量
            getInformAmount(){
                let that=this
                let queryData={}
                queryData.user_id=globaluserId()
                queryBedealtCount(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                       that.informAmount.noticetCount=response.data.data.noticetCount
                       that.informAmount.bedealtCount=response.data.data.bedealtCount
                    })
                })
            },
            //切换为消息中心
            showInformation(){
                this.type='1'
                this.number=0
                this.typeName='消息中心'
                this.informList=[]
                this.page.currentPage=1
                this.typeList=[{dictionaryTitle:'未读'},{dictionaryTitle:'已读'},{dictionaryTitle:'全部'}]
                this.onLoad()
                this.getInformAmount()

            },
            //切换为待办中心
            showBacklog(){
                this.type='2'
                this.number=0
                this.typeName='待办中心'
                this.informList=[]
                this.page.currentPage=1
                this.typeList=[{dictionaryTitle:'待办'},{dictionaryTitle:'已办'},{dictionaryTitle:'全部'}]
                this.onLoad()
                this.getInformAmount()
            },
            //确认类型
            confirmType(e,index){
                this.isShow=false
                this.loading = true
                this.finished=false
                this.informList=[]
                this.page.currentPage=1
                if(index==0){
                    this.number=0
                    //this.informList=[{title:'的实际发生的九分裤分等级考试管理付款克鲁赛德',time:'2021-10-12',creatorName:'张三',content:'第三方老师杜甫江阁房管局',state:'1',noticeStatus:'未读'},]
                }else if(index==2){
                    this.number=2
                    //this.informList=[
                              //{title:'对公付款官方公开复工',time:'2021-1-34',creatorName:'李四发',content:'给客人热把目光赫奇帕奇我国，个人风格你今儿复刻我发给',state:'2',noticeStatus:'已读'},]
                }else if(index==1){
                    this.number=1
                    //this.informList=[{title:'的实际发生的九分裤分等级考试管理付款克鲁赛德',time:'2021-10-12',creatorName:'张三',content:'第三方老师杜甫江阁房管局',state:'1',noticeStatus:'未读'},
                              //{title:'对公付款官方公开复工',time:'2021-1-34',creatorName:'李四发',content:'给客人热把目光赫奇帕奇我国，个人风格你今儿复刻我发给',state:'2',noticeStatus:'已读'},]
                }
                this.onLoad()
                this.getInformAmount()
            },
            //跳转到详情页
            detailsClick(index){
                    this.$router.push({
                        name : 'informDetails',
                        query : {
                          title:this.informList[index].title,
                          addTime:this.informList[index].addTime,
                          addStaffName:this.informList[index].addstaffName,
                          content:this.informList[index].content,
                          state:this.informList[index].state,
                          type:this.type,
                          noticeStatus:this.informList[index].noticeStatus,
                          id:this.informList[index].id,
                          introduction:this.informList[index].introduction,
                          dealtStatus:this.informList[index].dealtStatus
                        }
                    })
            },
            onLoad(){
                let that=this
                let queryData={}
                queryData.user_id=globaluserId()
                queryData.currentPage=that.page.currentPage
                queryData.numberPage=that.page.numberPage
                queryData.type=that.type
                queryData.currentLabel=1
                queryData.status=that.number
                queryBedealtList(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        let data=response.data.data.data
                        if(data){
                          for(let i=0;i<data.length;i++){
                            that.informList.push(data[i])
                          }
                        }
                        that.loading=false
                        if (that.informList.length >=response.data.data.pageCount) {
                            that.finished = true;
                        }
                        that.page.currentPage++
                    })
                })
            },
            onRefresh() {
                // 清空列表数据
                this.page.currentPage=1
                this.finished = false;
                this.informList = []
                this.loading = true;
                this.refreshing = false;
                responseUtil.alertMsg(this,"刷新成功")
                this.onLoad()
                this.getInformAmount()
            },
    //返回上一页
    leftReturn() {
      this.$router.go(-1)
    },
        }
    }
</script>

<style scoped>
    .nav-top /deep/ .van-nav-bar{
        background-color: white;
        border-bottom: 2px solid white;
    }
    .outside{
        overflow: hidden;
        width: 100%;
    }
    .small-icon{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .small-icon-rotate{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        margin-bottom: 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform:rotate(180deg);
    }
    .select{
        width: 100%;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
    }
    .selectActive{
        color: #fe5e3a;
    }
    .selectList{
        width: 100%;
        padding: 5px 0 14px 0;
        background-color: white;
    }
    .navbar{
        background-color: white;
        border-bottom: 2px solid white;
    }
    .header{
        width: 100%;
        height: auto;
        position: fixed;
        z-index: 8;
        top: 50px;
        background-color: #f8f8f8;
    }
    .choose{
        width: 100%;
        height: 56px;
        background-color: white;
        display: flex;
    }
    .choose-one{
        width: 187px;
        text-align: center;
    }
    .choose-one-textActive{
        font-size: 14px;
        color: #fe5e3a;
        font-weight: bold;
        margin-top: 10px;
    }
    .choose-one-text{
        font-size: 14px;
        color: #999999;
        margin-top: 10px;
    }
    .choose-one-icon{
        width: 20px;
        height: 4px;
        border-radius: 3px;
        background-color: #fe5e3a;
        margin: 10px 0 0 84px;
    }
    .choose-line{
        width: 1px;
        height: 17px;
        margin-top: 13px;
        background-color: #e0e0e0;
    }
    .search{
        margin: 15px 20px 0 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    input{
        font-size: 14px;
        margin-left: 15px;
        margin-top: 3px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: #d9d9d9;
    }
    .types{
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
    }
    .type{
        font-size: 14px;
        color: #fe5e3a;
        margin-left: 15px;
    }
    .typeActive{
        color: #fe5e3a;
        font-weight: bold;
    }
    .type-line{
        width: 1px;
        height: 12px;
        margin-top: -2px;
        background-color: #e0e0e0;
    }
    .leaseChange{
        font-size: 14px;
        color: black;
        margin-left: 15px;
    }
    .leaseChangeActive{
        font-size: 14px;
        color: #fe5e3a;
        margin-left: 15px;
    }
    .contracts{
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .contract{
        position: relative;
        margin: 0 15px 15px 15px;
        height: auto;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
    }
    .top-title-orange{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
    }
    .top-title-green{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#63cb96,#30d0ac);
        color: white;
    }
    .top-title-red{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: grey;
        color: white;
    }
    .position{
        margin: 15px 50px 0 15px;
        height: auto;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space:nowrap;
    }
    .tel-icon{
        width: 25px;
        height: 25px;
        position: absolute;
        top: 32px;
        right: 20px;
        background-image: url("../../../assets/images/relation-phone.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .details{
        margin-top: 15px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #e0e0e0;
        display: flex;
    }
    .details .info{
        width: 85.5px;
        height: auto;
        text-align: center;
    }
    .details .info .order{
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
    }
    .details .info .order-name{
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #fe5e3a;
        margin-top: 10px;
        margin-bottom: 15px;
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
        font-weight: bold;
    }
    .details .line{
        width: 1px;
        height: 40px;
        background-color: #e0e0e0;
        margin-top: 8px;
    }
    .contract-info{
        width: 100%;
        height: auto;
        padding: 15px 0 10px 0;
        border-bottom: 1px solid #e0e0e0;
    }
    .contract-text{
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
        font-size: 14px;
        white-space:nowrap;
    }
    .bottom{
        width: 330px;
        height: 60px;
        padding-right: 15px;
        overflow: hidden;
    }
    .bottom-sign{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-change{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-invalid{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#E02020,#FF431B);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-property{
        float: right;
        width: 90px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        right: 15px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-changeLease{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .blankWeight{
        height: 111px;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .block {
        width: 76%;
        height: 21.5%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    /*    获取密码DIV*/
    .getPassword{
        background: url("../../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right,#FFC274 ,#FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }
    /*    门锁密码*/
    .LockPassWord{
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }

    /*传入的密码值*/
    .password{
        color: #ff8247;
        font-size: 20px;
        margin-top: 40px;
        font-family: SimSun;
    }
    /*关闭按钮*/
    .closeImg{
        margin-top: 100px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size:18px 18px;
    }
        .van-overlay{
            background-color: rgba(80,80,80,.7);;
        }
    .bottom_iconDiv{
        float: left;
        width: 120px;
        height: 30px;
    }
    .pwd_IMG{
        float: left;
        width: 30px;
        height: 25px;
        margin-left: 15px;
        margin-top: 3px;
    }
    .icon{
        float: left;
        width: 120px;
        height: 30px;
        margin-top: 10px;
    }
    .getPwd{
        font-size: 14px;
        float: left;
        color: #9a9a9a;
        margin-top: 6px;
    }
    .inform-amount{
        color:white;
        margin-left:10px;
        background: red;
        border-radius:50%;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      font-size: 10px;
    }
    .amount{
      padding: 5px;
      font-size: 10px;
      color:#000000;
      margin-left:10px;
      background-color: #f2f2f2;
      border-radius:50%;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
</style>
